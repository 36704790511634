/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Packages
import * as React from 'react';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { Link } from 'gatsby';

// Style
import './index.scss';

// assets
import logoWhite from '../../images/footer/logo-white.svg';
import liIcon from '../../images/hero/linkedin.svg';

/* -------------------------------------------------------------------------- */
/*                                  Component                                 */
/* -------------------------------------------------------------------------- */

function PageFooter() {
  /* ********************************** HOOKS ********************************* */

  // Localization
  const { t } = useTranslation();
  const { menu, socialLinks } = t('footer', {
    returnObjects: true,
  });

  /* ******************************** RENDERING ******************************* */
  return (
    <footer className="page-footer py-60 bg-darkBlue">
      <div className="f-center w-100 mb-60 img-container">
        <img src={logoWhite} alt="" />
      </div>
      <div className="f-center mb-48 w-100-m">
        <ul className="f-center menu list-style-none flex-wrap w-100-m">
          {menu?.map(({ label, url }) => (
            <li key={label}>
              <Link to={url} className="text-decoration-none text-white">
                {label}
              </Link>
            </li>
          ))}
        </ul>
      </div>
      <hr className="w-100 m-0" />
      <div className="mt-48 f-center px-15-m">
        {socialLinks?.map(({ label, url }, index) => (
          <Link
            to={url}
            className={`f-v-center text-decoration-none text-white f16 f12-m medium ${
              index > 0 ? 'ms-40 ms-26-m f-center mb-48-m' : ''
            }`}
            key={label}
            target="_blank"
          >
            <img src={liIcon} alt="" className="me-8 social-icon" />
            {label}
          </Link>
        ))}
      </div>
    </footer>
  );
}

PageFooter.propTypes = {};

PageFooter.defaultProps = {};

export default PageFooter;
