export const isBrowser = typeof window !== 'undefined';

export const disableBodyScroll = () => {
  if (isBrowser) {
    document.getElementById('page-layout').style.overflowY = 'hidden';
  }
};

export const enableBodyScroll = () => {
  if (isBrowser) {
    document.getElementById('page-layout').style.overflowY = 'auto';
  }
};
