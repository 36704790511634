/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Packages
import * as React from 'react';
import PropTypes from 'prop-types';
// UI lib component

// Local UI components
import PageHeader from '../PageHeader';
import PageFooter from '../PageFooter';

// Style
import './index.scss';

/* -------------------------------------------------------------------------- */
/*                                  Component                                 */
/* -------------------------------------------------------------------------- */
/**
 * PageLayout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */
function PageLayout({ children, variant, hideFooter }) {
  /* ********************************** HOOKS ********************************* */

  // const data = useStaticQuery(graphql`
  //   query SiteTitleQuery {
  //     site {
  //       siteMetadata {
  //         title
  //       }
  //     }
  //   }
  // `);

  /* ******************************** RENDERING ******************************* */

  return (
    <div
      id="page-layout"
      className="page-layout flex flex-column between p-0 m-0"
      fluid
    >
      <PageHeader variant={variant} />
      <main>{children}</main>
      {!hideFooter && <PageFooter />}
    </div>
  );
}

PageLayout.propTypes = {
  children: PropTypes.node.isRequired,
  variant: PropTypes.string,
  hideFooter: PropTypes.bool,
};

PageLayout.defaultProps = {
  variant: 'primary',
  hideFooter: false,
};

export default PageLayout;
