/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Packages
import * as React from 'react';
import PropTypes from 'prop-types';
import { Link, useTranslation } from 'gatsby-plugin-react-i18next';
import { navigate } from 'gatsby';

// Style
import './index.scss';

// assets
import { Container } from 'react-grid-system';
import logo from '../../images/header/logo.svg';
import logoWhite from '../../images/footer/logo-white.svg';
import handIcon from '../../images/header/hand.svg';
import bars from '../../images/header/bars-solid.svg';
import xmark from '../../images/header/xmark-solid.svg';
import barsWhite from '../../images/header/bars-solid-white.svg';
import xmarkWhite from '../../images/header/xmark-solid-white.svg';
import { disableBodyScroll, enableBodyScroll } from '../Helpers';

/* -------------------------------------------------------------------------- */
/*                                  Component                                 */
/* -------------------------------------------------------------------------- */

function PageHeader({ variant }) {
  /* ********************************** HOOKS ********************************* */
  const [open, setOpen] = React.useState(false);
  const currentBars = variant === 'primary' ? bars : barsWhite;
  const currentxmark = variant === 'primary' ? xmark : xmarkWhite;

  // Localization
  const { t } = useTranslation();
  const { navList, cta } = t('header', {
    returnObjects: true,
  });
  React.useEffect(() => {
    if (open) {
      disableBodyScroll();
    } else {
      enableBodyScroll();
    }
  }, [open]);

  /* ******************************** RENDERING ******************************* */
  return (
    <header className={`page-header py-30 ${variant}`}>
      <Container>
        <div className="f-v-center between w-100">
          <Link
            to="/"
            className="text-decoration-none h-125 h-80-med h-60-m d-inline-block min-h-75 f-center"
            onClick={() => setOpen(false)}
          >
            <img
              src={variant === 'primary' ? logo : logoWhite}
              alt="home"
              className="w-auto h-100 m-0 h-60-m my-auto"
            />
          </Link>
          <nav className="flex">
            <ul
              className={`m-0 me-60 p-0 list-style-none d-none d-lg-flex align-center ${
                open ? 'open' : ''
              }`}
            >
              {navList?.map(({ label, url }) => (
                <li key={label}>
                  <Link
                    to={url}
                    className="text-decoration-none text-darkBlue"
                    onClick={() => setOpen(false)}
                  >
                    {label}
                  </Link>
                </li>
              ))}
            </ul>
            <div className="f-v-center" j>
              <button
                type="button"
                className={`f-center bg-dark border-none outline-none p-24 py-15-m text-white bg-darkBlue f16 f10-m br8 bold cursor-pointer ${
                  variant === 'secondary' && open ? 'bg-blue' : ''
                }`}
                onClick={() => {
                  navigate('/contact-us');
                  setOpen(false);
                }}
              >
                {cta?.label}
                <img src={handIcon} alt="" className="m-0 ms-10" />
              </button>
              <button
                type="button"
                className="bg-transparent border-none outline-none w-18 h-auto f-center ms-15-m d-lg-none"
                onClick={() => setOpen(!open)}
              >
                <img src={open ? currentxmark : currentBars} alt="" />
              </button>
            </div>
          </nav>
        </div>
      </Container>
    </header>
  );
}

PageHeader.propTypes = {
  variant: PropTypes.string.isRequired,
};

PageHeader.defaultProps = {};

export default PageHeader;
